.custom-datepicker::placeholder {
  color: #000000;
}

.custom-datepicker:disabled {
  background-color: white;
}

.custom-datepicker:disabled::placeholder {
  color: #e2e8f0;
  background-color: white;
}

.custom-datepicker:focus {
  outline: none !important;
}
